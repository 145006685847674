import React from 'react';
import SearchBarView from "./view";
import { useHistory } from 'react-router-dom';

const SearchBar = () => {
  const history = useHistory();

  const submitSearch = (value) => {
    history.push({ pathname: '/search', state: { keywordsOrMapId: value } })
  }

  return (
    <SearchBarView submitSearch={submitSearch} />
  )
}

export default SearchBar;