import React, { useEffect, useContext, useState } from 'react';
import { MainContext } from '../../services/Context/Context';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import LoginView from './view';
import { logout, login } from '../../Actions/LoginActions';

const Login = () => {
  const { dispatch, state } = useContext(MainContext);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const params = useLocation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params.search) {
      const newParams = new URLSearchParams(params.search);
      const targetMapID = newParams.get('mapID');
      if (params.search === '?logout') {
        newParams.delete('logout');
        logout({ dispatch, state });
      } else if (params.search === '?expired-token') {
        newParams.delete('expired-token');
        setErrorMessage('Your session has expired. Please log in again.');
      } else if (params.search === '?invalid-token') {
        newParams.delete('invalid-token');
        setErrorMessage('Re-authentication required. Please log in again.');
      }

      if (targetMapID) {
        // State seems to be inconsistent, so storing this value in session storage
        sessionStorage.setItem('desiredMap', targetMapID);
        newParams.delete('mapID');
      }

      history.replace({ search: newParams.toString() });
    } else if (state && state.user && state.user.token) {
      setRedirect(true);
    }
  }, [state, dispatch, params]);

  if (redirect) {
    if (state.user.token) {
      const desiredMapID = sessionStorage.getItem('desiredMap');
      if (desiredMapID !== null) {
        return <Redirect to={`/map/${desiredMapID}`} />
      }
    }

    // The user's state has a token, which means they are logged in.
    // Redirect them to the main catalog page.
    return <Redirect to='/' />
  }
  const handleSubmit = () => {
    login({ dispatch, username: email, password: password, setErrorMessage, setLoading });
  };

  return (
    <LoginView
      handleEmailChange={(e) => setEmail(e)}
      handlePasswordChange={(e) => setPassword(e)}
      handleSubmit={(e) => handleSubmit(e)}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      loading={loading}
    />
  );
};

export default Login;
