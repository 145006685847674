import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Media from 'react-media';

import SearchBar from './../SearchBar/container';

import Logo from '../../assets/images/logo-2-1';
import SearchSolid from '../../assets/images/search-solid';
import HamburgerIcon from '../../assets/images/bars-solid';
import XIcon from '../../assets/images/times-solid';

import './style.scss';

import { MainContext } from '../../services/Context/Context';
import { Button } from '@material-ui/core';
import { WP_ROOT_URL } from '../../constants/ApiConstants';

const HeaderView = () => {
  const [showSearch, setShowSearch] = useState(false);
  const history = useHistory();
  const { state } = useContext(MainContext);

  const redirectToMyAccount = () => {
    const token = state.user?.token;
    if (token) {
      window.location.assign(
        `${WP_ROOT_URL}/token-login.php?jwt=${token}&redirect=profile`,
      );
    } else {
      history.push('/login');
    }
  };

  const redirectToWordpress = (redirect, defaultUrl) => {
    const token = state.user?.token;
    if (token) {
      window.location.assign(
        `${WP_ROOT_URL}/token-login.php?jwt=${token}&redirect=${redirect}`,
      );
    } else {
      window.location.assign(defaultUrl)
    }
  };
  return (
    <div className="header-container">
      {/* <div className="container"> */}
      <Media query={{ minWidth: 1025 }}>
        <NavBar
          redirectToMyAccount={redirectToMyAccount}
          redirectToWordpress={redirectToWordpress}
          state={state}
          setShowSearch={setShowSearch}
          showSearch={showSearch}
          token={state.user?.token}
        />
      </Media>
      <Media query={{ maxWidth: 1024 }}>
        <MobileNavBar setShowSearch={setShowSearch}
                      showSearch={showSearch}
                      redirectToWordpress={redirectToWordpress}
        />
      </Media>
      {/* </div> */}
      {showSearch && history.location.pathname !== '/search' && <SearchBar />}
    </div>
  );
};

const NavBar = ({ redirectToMyAccount, state, setShowSearch, showSearch, redirectToWordpress }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-auto  header-links-container">
          <a onClick={() => redirectToWordpress('homepage', `${WP_ROOT_URL}`)} className="logo">
            <Logo styles={{ width: '194.32px', height: '34.48px' }} />
          </a>
          <Link to="/search">Browse Catalog</Link>
          {/* <a href={`${ApiConstants.WP_ROOT_URL}/exhibits`}>Exhibits</a> */}
          <a className='header-link clickable' onClick={() => redirectToWordpress('for-education', `${WP_ROOT_URL}/for-education`)}>For Education</a>
          <a className='header-link clickable' onClick={() => redirectToWordpress('about',`${WP_ROOT_URL}/about`)}>About</a>
          {/* <a href={`${ApiConstants.WP_ROOT_URL}/Donate`}>Donate</a> */}
        </div>
        {state?.user?.token ? (
          <div className="col-md-auto header-signin-container">
            <Link to={'#'} onClick={redirectToMyAccount}>
              My Account
            </Link>
            <button className="font-awesome-icon-search" onClick={() => setShowSearch(!showSearch)}>
              <SearchSolid className={'search-svg'} />
            </button>
          </div>
        ) : (
          <div className="col-md-auto header-signin-container">
            <a href={`${WP_ROOT_URL}/register`}>Register</a>
            <Link to="/login">Sign in</Link>
            <button className="font-awesome-icon-search" onClick={() => setShowSearch(!showSearch)}>
              <SearchSolid className={'search-svg'} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const MobileNavBar = ({redirectToWordpress}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="container container-mobile-nav">
      <div className="row">
        <div className="col logo-container">
          <a onClick={() => redirectToWordpress('homepage', `${WP_ROOT_URL}`)} className="logo">
            <Logo styles={{ width: '194.32px', height: '34.48px' }} />
          </a>
        </div>
        <div className="col mobile-links-container">
          <Button onClick={() => setOpen(!open)}>
            {!open ? (
              <HamburgerIcon styles={{ width: '25px', height: '25px' }} />
            ) : (
              <XIcon styles={{ width: '25px', height: '25px' }} />
            )}
          </Button>
        </div>
      </div>
      <div className={`row mobile-links ${!open ? 'collapsed' : ''}`}>
        <div className="col mobile-menu">
          <Link to="/search">Browse Catalog</Link>
          {/* <a href={`${ApiConstants.WP_ROOT_URL}/exhibits`}>Exhibits</a> */}
          <a className='mobile-header-link clickable' onClick={() => redirectToWordpress('for-education', `${WP_ROOT_URL}/for-education`)}>For Education</a>
          <a className='mobile-header-link clickable' onClick={() => redirectToWordpress('about',`${WP_ROOT_URL}/about`)}>About</a>
          {/* <a href={`${ApiConstants.WP_ROOT_URL}/Donate`}>Donate</a> */}
        </div>
      </div>
    </div>
  );
};

export default HeaderView;
