import React, { useState, useEffect } from 'react';
import './style.scss';
import { Form, Field, FormSpy } from 'react-final-form';
import Select from 'react-dropdown-select';
import { Table } from 'react-bootstrap';
import { ResultLineItem } from './ResultLineItem';
import Media from 'react-media';
import Button from './../../shared/Buttons/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import HamburgerIcon from './../../assets/images/bars-solid';

const MapSearchView = ({
  results,
  metadata,
  submitSearch,
  regions,
  initialValues,
  initialValuesForReset,
  setFilterState,
  languages,
  page,
  onPageChange,
  history,
}) => {
  const handleOnClick = (mapNumber) => history.push(`/map/${mapNumber}`);
  const languageOptions = () => {
    let languageObject = [];
    languageObject = languages?.map((l) => {
      return { label: l, value: l };
    });
    if (languageObject) {
      return [{ label: 'All', value: null }, ...languageObject];
    }
  };

  return (
    <div className="container map-search-container">
      <div className="row results-info">
        <div className="col-md-12">
          <div className="search-catalog-header">
            <div className="header-text">SEARCH CATALOG USING ANY OR ALL FILTERS</div>
            {metadata && (
              <span style={{ fontWeight: 'bold' }}>
                Showing {parseInt((metadata.currentPage - 1) * metadata.limitValue) + 1}-
                {parseInt(metadata.currentPage * metadata.limitValue)} of {metadata.totalCount}{' '}
                Catalog results
              </span>
            )}
          </div>
        </div>
        {/* <div className="col-md-2">
          <span>Sort by </span>
          <span>Best Match</span>
        </div> */}
      </div>
      <div className="row" style={{ minHeight: '830px' }}>
        <div className="col search-container">
          <Media query={{ minWidth: 578 }}>
            <SearchContainer
              setFilterState={setFilterState}
              initialValuesForReset={initialValuesForReset}
              regions={regions}
              initialValues={initialValues}
              languages={languages && languageOptions()}
              submitSearch={submitSearch}
            />
          </Media>
          <Media query={{ maxWidth: 577 }}>
            <MobileSearchContainer
              setFilterState={setFilterState}
              initialValuesForReset={initialValuesForReset}
              regions={regions}
              initialValues={initialValues}
              languages={languages && languageOptions()}
              submitSearch={submitSearch}
            />
          </Media>
        </div>
        <div className="col">
          <Media query={{ minWidth: 578 }}>
            <MapTable results={results} handleOnClick={handleOnClick} />
          </Media>
          <Media query={{ maxWidth: 577 }}>
            <MapTableMobile results={results} handleOnClick={handleOnClick} />
          </Media>

          {metadata && (
            <div className="pagination-container">
              <div
                className={
                  metadata.currentPage > 1
                    ? 'pagination-button-active'
                    : 'pagination-button-disabled'
                }
                onClick={() => onPageChange(metadata.currentPage - 1)}
              >
                Previous
              </div>

              <div>
                <input
                  className="pagination-input"
                  type="number"
                  pattern="[0-9]*"
                  value={page}
                  onChange={(e) => onPageChange(e.target.value)}
                />
                <span className="pagination-text">of</span>
                <span className="pagination-count"> {metadata.totalPages}</span>
              </div>
              <div
                className={
                  metadata.currentPage < metadata.totalPages
                    ? 'pagination-button-active'
                    : 'pagination-button-disabled'
                }
                onClick={() => onPageChange(metadata.currentPage + 1)}
              >
                Next
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MapSearchView;

const MapTable = ({ results, handleOnClick }) => {
  return (
    <Table className="results-table">
      <thead>
        <tr>
          <th></th>
          <th>Map ID</th>
          <th>Title</th>
          <th>Year</th>
          <th>Region</th>
        </tr>
      </thead>
      <tbody>
        {results &&
          results
            .sort((a, b) => a.yearOfOrigin - b.yearOfOrigin)
            .map((result, idx) => {
              return (
                <ResultLineItem
                  key={`results-${idx}`}
                  onClick={() => handleOnClick(result.number, result.id)}
                  result={result}
                />
              );
            })}
      </tbody>
    </Table>
  );
};

const MapTableMobile = ({ results, handleOnClick }) => {
  return (
    <Table className="results-table-mobile">
      <thead>
        <tr>
          <th></th>
          <th>Map ID</th>
          <th>Year</th>
          <th>Region</th>
        </tr>
      </thead>
      <tbody>
        {results &&
          results
            .sort((a, b) => a.yearOfOrigin - b.yearOfOrigin)
            .map((result) => {
              return (
                <ResultLineItem
                  key={result.id}
                  onClick={() => handleOnClick(result.number, result.id)}
                  result={result}
                  mobile={true}
                />
              );
            })}
      </tbody>
    </Table>
  );
};

const SearchContainer = ({
  regions,
  languages,
  submitSearch,
  initialValues,
  initialValuesForReset,
}) => {
  const onSubmit = (values) => {
    submitSearch({ ...values, creator: { name: inputValue, id: value?.id } });
  };

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const GET_CREATOR_NAMES = gql`
    query creatorNames($name: String!) {
      creatorNames(name: $name) {
        name
        id
      }
    }
  `;
  const [getCreatorNames] = useLazyQuery(GET_CREATOR_NAMES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const configuredOptions = data.creatorNames.map((n) => ({ name: n.name, id: n.id }));
      setOptions(configuredOptions);
    },
  });

  const getOptions = (value) => {
    if (value?.length > 2) {
      getCreatorNames({ variables: { name: value } });
    }
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (initialValues?.creator?.name || initialValues?.creator?.id) {
      setValue({ name: initialValues.creator.name || '', id: initialValues.creator.id });
    }
  }, [initialValues]);

  const submitClear = () => {
    submitSearch({}, true);
    setValue(null);
  };

  return (
    <div className="search-filter">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpy>
              {(props) => (
                <div className="clear-search">
                  <button
                    type="button"
                    onClick={() => {
                      props.form.initialize(initialValuesForReset);
                      submitClear();
                    }}
                  >
                    Clear Search
                  </button>
                </div>
              )}
            </FormSpy>
            <div className="filter-container">
              <span className="filter-label">KEYWORDS OR MAP ID NUMBER</span>
              <Field name="keywordsOrMapId">
                {(props) => <input {...props.input} className="text-input" />}
              </Field>
            </div>
            <div className="filter-container">
              <span className="filter-label">TITLE WORD</span>
              <Field name="titleWord">
                {(props) => <input {...props.input} className="text-input" />}
              </Field>
            </div>
            <div className="filter-container">
              <div className="year-range-inputs">
                <div>
                  <div className="filter-label">YEAR</div>
                  <Field name="fromYear">
                    {(props) => (
                      <input {...props.input} className="text-input" placeholder="Earliest" />
                    )}
                  </Field>
                </div>
                <div>
                  <span className="filter-label"></span>
                  <Field name="toYear">
                    {(props) => (
                      <input {...props.input} className="text-input" placeholder="Latest" />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className="filter-container">
              <span className="filter-label">CREATOR</span>
              <Autocomplete
                id="creator-autocomplete"
                open={open}
                style={{ width: '100%' }}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                filterOptions={(x) => x}
                autoComplete
                freeSolo
                getOptionSelected={(option, value) => {
                  return option.name === value.name;
                }}
                getOptionLabel={(option) => option.name}
                onBlur={(e) => {
                  e.persist();
                  setInputValue(e.target.value);
                }}
                onChange={(event, newValue) => {
                  setInputValue(newValue);
                  setValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  getOptions(newInputValue);
                  setInputValue(newInputValue);
                }}
                options={options}
                loading={loading}
                name="creator"
                value={value}
                renderInput={(params) => (
                  <Field name="creator" {...params}>
                    {(props) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={() => {
                          props.input.onChange(params.inputProps.value);
                        }}
                        // InputProps={{
                        //   ...params.InputProps,
                        //   endAdornment: (
                        //     <>
                        //       {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        //       {params.InputProps.endAdornment}
                        //     </>
                        //   ),
                        // }}
                      />
                    )}
                  </Field>
                )}
              />
            </div>
            <div className="filter-container">
              <span className="filter-label">PLACE</span>
              <Field name="placeName">
                {(props) => <input {...props.input} className="text-input" />}
              </Field>
            </div>
            <div className="filter-container">
              <span className="filter-label">REGION</span>
              <Field name="region">
                {(props) => (
                  <Select
                    name={props.input.name}
                    options={regions && [{ fullName: 'All', id: null }, ...regions]}
                    onChange={(value) => {
                      props.input.onChange(value);
                    }}
                    labelField={'fullName'}
                    valueField={'id'}
                    values={props.input.value || []}
                    searchable={false}
                    multi={false}
                  />
                )}
              </Field>
            </div>
            <div className="filter-container">
              <span className="filter-label">LANGUAGE</span>
              <Field name="language">
                {(props) => (
                  <Select
                    name={props.input.name}
                    options={languages}
                    onChange={(value) => {
                      props.input.onChange(value);
                    }}
                    labelField={'label'}
                    valueField={'value'}
                    values={props.input.value || []}
                    searchable={false}
                  />
                )}
              </Field>
            </div>
            <Button type="submit">Search</Button>
          </form>
        )}
      />
    </div>
  );
};

const MobileSearchContainer = ({
  regions,
  languages,
  submitSearch,
  initialValues,
  initialValuesForReset,
}) => {
  const onSubmit = (values) => {
    submitSearch(values);
  };

  const [value, setValue] = useState(null);
  const [, setInputValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const GET_CREATOR_NAMES = gql`
    query creatorNames($name: String!) {
      creatorNames(name: $name)
    }
  `;
  const [getCreatorNames] = useLazyQuery(GET_CREATOR_NAMES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const configuredOptions = data.creatorNames.map((n) => ({ name: n }));
      setOptions(configuredOptions);
    },
  });

  const getOptions = (value) => {
    if (value?.length > 3) {
      getCreatorNames({ variables: { name: value } });
    }
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const submitClear = () => {
    submitSearch({}, true);
    setValue(null);
  };

  const [showSearch, setShowSearch] = useState(false);
  return (
    <div className="search-filter">
      <div className="mobile-filter-header-container">
        <span className="mobile-filter-header">Filter Search</span>
        <button
          onClick={() => setShowSearch(!showSearch)}
          style={{ backgroundColor: 'transparent', cursor: 'pointer' }}
        >
          <HamburgerIcon styles={{ width: '20px', height: '20px', cursor: 'pointer' }} />
        </button>
      </div>
      {showSearch && (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormSpy>
                {(props) => (
                  <div className="clear-search">
                    <button
                      type="button"
                      onClick={() => {
                        props.form.initialize(initialValuesForReset);
                        submitClear();
                      }}
                    >
                      Clear Search
                    </button>
                  </div>
                )}
              </FormSpy>
              <div className="filter-container">
                <span className="filter-label">KEYWORDS OR MAP ID NUMBER</span>
                <Field name="keywordsOrMapId">
                  {(props) => <input {...props.input} className="text-input" />}
                </Field>
              </div>
              <div className="filter-container">
                <span className="filter-label">TITLE WORD</span>
                <Field name="titleWord">
                  {(props) => <input {...props.input} className="text-input" />}
                </Field>
              </div>
              <div className="filter-container">
                <div className="year-range-inputs">
                  <div>
                    <div className="filter-label">YEAR</div>
                    <Field name="fromYear">
                      {(props) => (
                        <input {...props.input} className="text-input" placeholder="Earliest" />
                      )}
                    </Field>
                  </div>
                  <div>
                    <span className="filter-label"></span>
                    <Field name="toYear">
                      {(props) => (
                        <input {...props.input} className="text-input" placeholder="Latest" />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              {/* <div className="filter-container">
                <span className="filter-label">CREATOR</span>
                <Field name="creator">
                  {(props) => <input {...props.input} className="text-input" />}
                </Field>
              </div> */}
              <div className="filter-container">
                <span className="filter-label">CREATOR</span>
                <Autocomplete
                  id="creator-autocomplete"
                  open={open}
                  style={{ width: '100%' }}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  filterOptions={(x) => x}
                  autoComplete
                  freeSolo
                  getOptionSelected={(option, value) => {
                    return option.name === value.name;
                  }}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setInputValue(newValue);
                    setValue(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    getOptions(newInputValue);
                    setInputValue(newInputValue);
                  }}
                  options={options}
                  loading={loading}
                  name="creator"
                  value={value}
                  renderInput={(params) => (
                    <Field name="creator" {...params}>
                      {(props) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value}
                          onChange={props.input.onChange(params.inputProps.value)}
                          // InputProps={{
                          //   ...params.InputProps,
                          //   endAdornment: (
                          //     <>
                          //       {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          //       {params.InputProps.endAdornment}
                          //     </>
                          //   ),
                          // }}
                        />
                      )}
                    </Field>
                  )}
                />
              </div>
              <div className="filter-container">
                <span className="filter-label">PLACE</span>
                <Field name="placeName">
                  {(props) => <input {...props.input} className="text-input" />}
                </Field>
              </div>
              <div className="filter-container">
                <span className="filter-label">REGION</span>
                <Field name="region">
                  {(props) => (
                    <Select
                      name={props.input.name}
                      options={regions && [{ fullName: 'All', id: null }, ...regions]}
                      onChange={(value) => {
                        props.input.onChange(value);
                      }}
                      labelField={'fullName'}
                      valueField={'id'}
                      values={props.input.value}
                      searchable={false}
                      multi={false}
                    />
                  )}
                </Field>
              </div>
              <div className="filter-container">
                <span className="filter-label">LANGUAGE</span>
                <Field name="language">
                  {(props) => (
                    <Select
                      name={props.input.name}
                      options={languages}
                      onChange={(value) => {
                        props.input.onChange(value);
                      }}
                      labelField={'label'}
                      valueField={'value'}
                      values={props.input.value}
                      searchable={false}
                    />
                  )}
                </Field>
              </div>
              <Button type="submit">Search</Button>
            </form>
          )}
        />
      )}
    </div>
  );
};
