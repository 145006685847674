import React, { useEffect } from 'react';

import './styles.scss';

const PopupDoc = ({ className, closeButton, docSrc }) => {
  return (
    <div className={className}>
      {closeButton}
      <iframe width="100%" height="100%" src={docSrc}></iframe>
    </div>
  );
};

export default PopupDoc;
