import * as PropTypes from "prop-types";
import React from "react";

export function ResultLineItem(props) {
    return <tr onClick={props.onClick} style={{cursor: "pointer"}}>
        <td>
            <div className="result-image" style={{backgroundImage: `url(${props.result.thumbnail})`}}/>
        </td>
        <td className="result-data">ID:{props.result.number}</td>
        {!props.mobile && <td className="result-data result-title" >{props.result.title}</td>}
        <td className="result-data">{props.result.yearOfOrigin}</td>
        <td className="result-data">{props.result.region}</td>
    </tr>;
}

ResultLineItem.propTypes = {
    onClick: PropTypes.func,
    result: PropTypes.any
};