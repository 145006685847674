import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Button extends Component {

  render() {

    const { props: {
      text, className, id, onClick,type, children, disabled }
    } = this;

    return(
      <button
        className={`${className} button-primary`}
        onClick={() => onClick()}
        type={type || ''}
        disabled={disabled}
        id={id}
      >
        {text || children}
      </button>
    );
  }
}

export default Button;

Button.propTypes = {
  disabled: PropTypes.bool,
  employerSettings: PropTypes.object,
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  type: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  className: 'button-primary',
  disabled: false,
  onClick: () => {},
  text: '',
  children: '',
};
