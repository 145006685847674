import React, { cloneElement, useContext } from 'react';
import { MainContext } from '../Context/Context';

import './style.scss';

const CloseButton = ({ onClick }) => (
  <button onClick={onClick} className="close_btn">
    +
  </button>
);

const ModalContainer = () => {
  const {
    state: { modal },
    dispatch,
  } = useContext(MainContext);

  const onClick = () => dispatch({ type: 'CLOSE_MODAL' });

  return (
    <div className={`popup-modal ${modal?.isOpen ? 'popup-modal--open' : ''}`}>
      {modal?.component
        ? cloneElement(modal?.component, {
            className: 'popup-modal__content',
            closeButton: <CloseButton onClick={onClick} />,
          })
        : null}
    </div>
  );
};

export default ModalContainer;
