import React, { createContext, useReducer, useContext } from 'react';
import ModalContainer from '../Modal/ModalContainer';
// import setAuthCookie from '../SetAuthCookie'

let MainReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        modal: {
          isOpen: true,
          component: action.payload,
        },
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        modal: {
          isOpen: false,
          component: null,
        },
      };
    case 'UPDATE_USER':
      return { ...state, userName: action.payload };
    case 'LOGIN':
      sessionStorage.setItem('user', JSON.stringify(action.payload));
      // if (action.payload.token) {
      //   setAuthCookie(action.payload.token)
      // }
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };
    case 'LOGOUT':
      sessionStorage.removeItem('user');
      return {
        ...state,
        user: {
          loading: false,
          error: false,
        },
      };
    case 'PAGE_NO':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const initialState = {
  user: {
    ...JSON.parse(sessionStorage.getItem('user')),
    loading: false,
    error: '',
    page: 1,
  },
  modal: {
    isOpen: false,
    component: null,
  },
};

const MainContext = createContext([initialState, (obj) => obj]);

function MainContextProvider(props) {
  const [state, dispatch] = useReducer(MainReducer, initialState);
  return <MainContext.Provider value={{ state, dispatch }}>{props.children}</MainContext.Provider>;
}

function useMainContext() {
  return useContext(MainContext);
}

export { MainContext, MainContextProvider, useMainContext };
