import { Api } from '../services/Api';
import {WP_ROOT_URL} from '../constants/ApiConstants'
const initialState = {
  user: {
    auth_token: '',
    id: null,
    role: 'Accounting',
    email: '',
    loading: false,
    error: '',
  },
};

export const login = ({ dispatch, username, password, setErrorMessage, setLoading }) => {
  dispatch({ type: 'LOGIN', payload: { ...initialState, loading: true } });
  Api.post(
    `${WP_ROOT_URL}/wp-json/jwt-auth/v1/token`,
    { username: username, password: password },
    setErrorMessage,
    setLoading,
  ).then(
    (res) => {
      dispatch({
        type: 'LOGIN',
        payload: { ...res, loading: false, error: '' },
      });
    },
    (errorRes) => {
      dispatch({
        type: 'LOGIN',
        payload: { auth_token: '', loading: false, error: 'Invalid Credentials' },
      });
      console.log(errorRes);
    },
  );
};

export const logout = ({ dispatch, state }) => {
  dispatch({ type: 'LOGOUT', payload: { ...state, loading: true } });
};
