import React, { useEffect } from 'react';
import MapSearchView from './view';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import {
  getRegions,
  getLanguages,
  searchMaps,
  formatMapSearchInput,
} from '../../Actions/MapActions';
import { useState } from 'react';
import Button from './../../shared/Buttons/Button';
import useHistoryState from './../../services/HistoryState';
import { useLocation } from 'react-router';
import { WP_ROOT_URL } from '../../constants/ApiConstants';
import { useMainContext } from '../../services/Context/Context';

function MapSearch({ history }) {
  const { state, dispatch } = useMainContext();
  const initialFilterValues = {
    keywordsOrMapId: null,
    titleWord: null,
    fromYear: null,
    toYear: null,
    creator: { name: '', id: null },
    placeName: null,
    region: [{ fullName: 'All', id: null }],
    language: [{ label: 'All', value: null }],
  };
  const [getMaps, { data: map_data }] = useLazyQuery(searchMaps, {
    fetchPolicy: 'cache-and-network',
  });
  const { data: region_data } = useQuery(getRegions);
  const { data: languageData } = useQuery(getLanguages);
  const [filter, setFilter] = useState(initialFilterValues);
  const [filterState, setFilterState] = useHistoryState('filterState', '');

  const page = state.page || 1;
  const setPage = (page) => dispatch({ type: 'PAGE_NO', payload: { page } });

  const searchParams = useLocation();

  const submitSearch = (formValues, resetPage = false) => {
    if (resetPage) {
      const formattedSearchInput = formatMapSearchInput(initialFilterValues);
      setPage(1);
      if (history.location.state) {
        delete history.location.state;
      }
      setFilterState({ ...initialFilterValues, page: 1 });
      setFilter(initialFilterValues);
      getMaps({ variables: { filter: formattedSearchInput, page: page } });
    } else {
      const formattedSearchInput = formatMapSearchInput(formValues);
      getMaps({ variables: { filter: formattedSearchInput, page: page } });
      setFilterState({ ...formValues, page: page });
      setFilter(formValues);
    }
  };

  const updatePage = (e) => {
    let newPage = parseInt(e);
    setPage(parseInt(e));
    setFilterState({ ...filterState, page: newPage });
    if (!isNaN(newPage)) {
      getMaps({ variables: { filter: formatMapSearchInput(filter), page: newPage } });
    }
  };

  useEffect(() => {
    const searchFromWp = searchParams?.search.replace('+', ' ').replace('?terms=', '');
    if (searchFromWp) {
      const queryParams = new URLSearchParams(searchParams.search);
      if (queryParams.has('terms')) {
        queryParams.delete('terms');
        history.replace({
          search: queryParams.toString(),
        });
      }
      submitSearch({ ...initialFilterValues, keywordsOrMapId: searchFromWp });
    } else if (history.location?.state?.region) {
      const internalRegion = history.location.state.region;
      delete history.location.state.region;
      submitSearch({ ...initialFilterValues, region: internalRegion });
    } else if (history.location?.state?.placeName) {
      const internalPlaceName = history.location.state.placeName;
      delete history.location.state.placeName;
      submitSearch({ ...initialFilterValues, placeName: internalPlaceName });
    } else if (history.location?.state?.keywordsOrMapId) {
      const internalKeyordsOrMapId = history.location.state.keywordsOrMapId;
      delete history.location.state.keywordsOrMapId;
      submitSearch({ ...initialFilterValues, keywordsOrMapId: internalKeyordsOrMapId });
    } else if (history.location?.state?.filterState) {
      const filterNoPage = Object.assign({}, filterState);
      delete filterNoPage.page;
      submitSearch(filterNoPage);
    } else {
      getMaps({ variables: { page: page } });
    }
  }, []);

  return (
    <>
      <MapSearchView
        history={history}
        results={map_data && map_data.mapSearch.collection}
        metadata={map_data && map_data.mapSearch.metadata}
        initialValues={filter}
        initialValuesForReset={initialFilterValues}
        setFilterState={setFilterState}
        submitSearch={submitSearch}
        page={page}
        regions={region_data && region_data.regions}
        languages={languageData && languageData.languages}
        onPageChange={(e) => updatePage(e)}
      />
      <div className="receive-updates-container">
        <div className="container receive-updates">
          <div className="row email-list-row">
            <div className="col description-column">
              <div className="header">
                Be the first to know when maps are added to the catalog.{' '}
              </div>
              {/* <div className="text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </div> */}
            </div>
            <div className="col form-column">
              {/* <div className="header">Receive the latest updates</div>
              <div className="email-list-container">
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="input-container">
                        <Field name="firstName">
                          {(props) => <input {...props.input} className="text-input" placeholder="First Name" />}
                        </Field>
                      </div>
                      <div className="input-container">
                        <Field name="email">
                          {(props) => <input {...props.input} className="text-input" placeholder="Email" />}
                        </Field>
                      </div>
                      <Button type="submit">Sign Up</Button>
                    </form>
                  )}
                />
              </div> */}
              <Button onClick={() => window.location.assign(`${WP_ROOT_URL}/register`)}>
                Register
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MapSearch;
