import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ render: ComposedComponent, ...rest }) => {

  class Authentication extends Component {

    handleRender(props) {
      if (!this.props.authToken) {
        return <Redirect to={{
          pathname: '/',
          state: {
            from: props.location,
            message: 'You need to sign in'
          }
        }} />;
      } else {
        return <ComposedComponent {...props} {...this.props} />;
      }
    }

    render() {
      return (
        <Route {...rest} render={this.handleRender.bind(this)}/>
      );
    }
  }

  const AuthenticationContainer = withRouter(Authentication);
  return <AuthenticationContainer />;
};

PrivateRoute.propTypes = {
  authToken: PropTypes.string,
  render: PropTypes.func
};

PrivateRoute.defaultProps = {
  authenticated: false
};
