import React from 'react';
import './style.scss';
import { WP_ROOT_URL } from '../../constants/ApiConstants';
import Spinner from '../../assets/images/spinner.svg';
import { Image } from 'react-bootstrap';

const LoginView = ({
  handleEmailChange,
  handlePasswordChange,
  handleSubmit,
  errorMessage,
  loading,
}) => {
  return (
    <div className="login-container container">
      <h2 className="login-title">Sign in to Afriterra</h2>
      <div className="input-container">
        <label>EMAIL</label>
        <input onChange={(e) => handleEmailChange(e.target.value)} />
      </div>
      <div className="input-container">
        <label>PASSWORD</label>
        <input type="password" onChange={(e) => handlePasswordChange(e.target.value)} />
      </div>
      <div className="checkbox-input-container">
        <input type="checkbox" />
        <label>Remember me</label>
      </div>
      {loading && <Image src={Spinner} style={{width: "48px", height: "48px", marginBottom: "16px"}}/>}
      {!loading && (
        <>
          <div>{errorMessage}</div>
          <div>
            <button className="login-button" onClick={(e) => handleSubmit(e)}>
              Sign In
            </button>
          </div>
        </>
      )}
      <div>
        <span className="cta-text">New to Afriterra?</span>{' '}
        <a href={`${WP_ROOT_URL}/register`} className="cta-link">
          Create Account
        </a>
      </div>
      <a href={`${WP_ROOT_URL}/password-reset`} className="reset-password">
        Reset Password
      </a>
    </div>
  );
};

export default LoginView;
