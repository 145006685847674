/**
 * Dynamic script loading hook.
 */
import React, { useEffect } from 'react';

// If no callback is provided, the script will not be removed on unmount. This
// kinda matters if the script loading is not idempotent (for some reason
// MathJax is not, which is one of the scripts I was using this for) or
// if you need the callback to happen again.
const useZoomifyScript = (scriptId, mapNumber, imageUrl, authenticated) => {
  useEffect(() => {
    const existingScript = document.getElementById(scriptId);
    const existingStyles = document.getElementById('zoomifyStyles');

    if (existingStyles) {
      existingStyles.remove();
    }

    if (existingScript) {
      existingScript.remove();
    }

    const styleScript = document.createElement('style');
    styleScript.type = 'text/css';
    styleScript.id = 'zoomifyStyles';
    styleScript.innerHTML = `#zoomifyContainer${mapNumber} { height:640px; }`;

    const script = document.createElement('script');
    script.id = scriptId;

    script.innerHTML = `Z.showImage("zoomifyContainer${mapNumber}","${imageUrl}","zSkinPath=/zoomify/assets/skins/dark&zViewerID=Viewer${mapNumber}&zRotationVisible=1");`;

    if ((script && authenticated) || (script && mapNumber === 74)) {
      if (imageUrl) {
        document.head.appendChild(styleScript);
        document.head.appendChild(script);
      }
    }
  }, [imageUrl, authenticated, mapNumber, scriptId]);
};

export default useZoomifyScript;
