import { Link } from 'react-router-dom';
import React from 'react';
import Button from './../../shared/Buttons/Button';

import './style.scss';
import { useQuery } from '@apollo/react-hooks';
import { getRegions } from '../../Actions/MapActions';
import { WP_ROOT_URL } from '../../constants/ApiConstants';

const ByLocation = () => {
  const { data: region_data, loading } = useQuery(getRegions);

  const PlaceNames = [
    "Algeria",
    "Angola",
    "Benin",
    "Botswana",
    "Burkina Faso",
    "Burundi",
    "Cameroon",
    "Central African Republic",
    "Chad",
    "Congo",
    "Cote D'Ivoire",
    "Djibouti",
    "Egypt",
    "Equatorial Guinea",
    "Eritrea",
    "Ethiopia",
    "Gabon",
    "Gambia",
    "Ghana",
    "Guinea Bissau",
    "Kenya",
    "Liberia",
    "Libya",
    "Madagascar",
    "Malawi",
    "Mali",
    "Mauritania",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Niger",
    "Nigeria",
    "Rwanda",
    "Senegal",
    "Sierra Leone",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Sudan",
    "Tanzania",
    "Togo",
    "Tunisia",
    "Uganda",
    "Western Sahara",
    "Zambia",
    "Zimbabwe"
  ]
  function importAll(r) {
    let images = {};
    r.keys().forEach((item,) => { images[item.replace('./', '')] = r(item); });
    return images
  }
  const LocationMaps = importAll(require.context('../../assets/images/bylocation', false, /\.(png|jpe?g|svg)$/));

  return (
    <>
      <div className="container map-search-container">
        <div className="row results-info">
          <div className="col-md-12">
            <div className="search-catalog-header">
              <div className="header-text">BROWSE BY LOCATION
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="location-card-row">
              {!loading && region_data.regions.map((n, idx) => (
                <Link to={{ pathname: '/search', state: { region: [{id: n.id, fullName: n.fullName }] } }} key={`region-name-${idx}`}>
                  <div className="location-card">
                    <img src={LocationMaps[`${n.fullName}.png`].default} alt={`Low resolution map of ${n.fullName}`} />
                    <div className="location-label">
                      {n.fullName}
                    </div>
                  </div>
                </Link>
              ))}
              {!loading && PlaceNames.map((n, idx) => (
                <Link to={{ pathname: '/search', state: { placeName: n } }} key={`place-name-${idx}`}>
                  <div className="location-card">
                  <img src={LocationMaps[`${n !== "Cote D'Ivoire" ? n : "Cote d_Ivoire"}.png`].default} alt={`Low resolution map of ${n}`} />
                    <div className="location-label">
                      {n !== "Cote D'Ivoire" ? n : "Côte D'Ivoire"}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </ div>
      <div className="receive-updates-container">
        <div className="container receive-updates">
          <div className="row email-list-row">
            <div className="col description-column">
              <div className="header">
                Be the first to know when maps are added to the catalog.{' '}
              </div>
            </div>
            <div className="col form-column">
              <Button onClick={() => window.location.assign(`${WP_ROOT_URL}/register`)}>
                Register</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ByLocation;