import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { MapDetail } from '../../screens/MapDetail';
import { MapSearch } from '../../screens/MapSearch';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Login } from '../../screens/Login';
import { PrivateRoute } from '../../components/routing/PrivateRoute';
import Account from './../../screens/Account/container';
import ByLocation from '../../screens/MapSearch/ByLocation';
import ModalContainer from '../../services/Modal/ModalContainer';

const Screen = () => {
  return (
    <div className="page-wrapper">
      <ModalContainer />
      <Header />
      <Switch>
        <Route exact path="/" render={(props) => <MapSearch {...props} />} />
        <Route exact path="/location" render={(props) => <ByLocation {...props} />} />
        <Route exact path="/map/:mapNumber" render={(props) => <MapDetail {...props} />} />
        <Route exact path="/search" render={(props) => <MapSearch {...props} />} />
        <Route exact path="/login" render={(props) => <Login {...props} />} />
        <Route path="/viewMap.cmd">
          <Redirect to={`/map/${+new URLSearchParams(window.location.search).get('number')}`} />
        </Route>
        <PrivateRoute>
          <Route path="/account" render={(props) => <Account {...props} />} />
        </PrivateRoute>
      </Switch>
      <Footer />
    </div>
  );
};

export default Screen;
