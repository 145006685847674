import React, { useContext } from 'react';
import MapDetailView from "./view";
import { useQuery } from '@apollo/react-hooks';
import { getMapByNumber } from "../../Actions/MapActions";
import { useParams } from "react-router";
import { MainContext } from "../../services/Context/Context";

const MapDetail = () => {
  const { mapNumber } = useParams();

  const { state } = useContext(MainContext);

  const { data } = useQuery(getMapByNumber, {
    variables: { mapNumber: parseInt(mapNumber), authToken: state.user?.token }
  })

  let sections = [{ name: "Map Overview", keys: ['title', 'subTitle', "continent", "region", "placeNames", "yearOfOrigin", "publishOfOrigin", "language"] },
  { name: "Creators", keys: ['cartographers', 'publishers', 'engravers', 'otherContributors'] },
  { name: "Specifications", keys: ['height', 'width', 'units', 'sizeClass', 'scale', 'colorType'] },
  { name: "Four Corner Coordinates", keys: ['northernmostLatitude', 'southernmostLatitude', 'westernmostLongitude', 'easternmostLongitude'] }]

  sessionStorage.removeItem('desiredMap');

  return (
    <MapDetailView sections={sections} map={data && data.mapByNumber} user={state.user && state.user} />
  )
}

export default MapDetail;