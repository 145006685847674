import { gql } from 'apollo-boost';
import { isEmpty } from 'lodash';

export const getMap = gql`
  query GetMapById($id: ID!) {
    map(id: $id) {
      id
      number
      title
      subTitle
      continent
      imageUrl
      region
      placeNames
      yearOfOrigin
      language
      publishOfOrigin
      height
      width
      units
      sizeClass
      scale
      colorType
      northernmostLatitude
      southernmostLatitude
      westernmostLongitude
      easternmostLongitude
      notes
      timestamp
      cartographers {
        firstName
        lastName
        middleName
        organization
        suffix
      }
      publishers {
        firstName
        lastName
        middleName
        organization
        suffix
      }
      engravers {
        firstName
        lastName
        middleName
        organization
        suffix
      }
      otherContributors {
        firstName
        lastName
        middleName
        organization
        suffix
      }
    }
  }
`;
export const getMapByNumber = gql`
  query GetMapByNumber($mapNumber: Int!) {
    mapByNumber(mapNumber: $mapNumber) {
      id
      number
      title
      subTitle
      continent
      imageUrl
      region
      placeNames
      yearOfOrigin
      language
      publishOfOrigin
      height
      width
      units
      sizeClass
      scale
      colorType
      northernmostLatitude
      southernmostLatitude
      westernmostLongitude
      easternmostLongitude
      notes
      timestamp
      cartographers {
        firstName
        lastName
        middleName
        organization
        suffix
      }
      publishers {
        firstName
        lastName
        middleName
        organization
        suffix
      }
      engravers {
        firstName
        lastName
        middleName
        organization
        suffix
      }
      otherContributors {
        firstName
        lastName
        middleName
        organization
        suffix
      }
    }
  }
`;

export const searchMaps = gql`
  query mapSearch($filter: SearchCatalogFilter, $page: Int!) {
    mapSearch(filter: $filter, perPage: 50, page: $page) {
      collection {
        id
        number
        title
        yearOfOrigin
        thumbnail
        region
      }
      metadata {
        totalCount
        currentPage
        totalPages
        limitValue
      }
    }
  }
`;

export const formatMapSearchInput = (values) => {
  const formattedSearchInput = Object.assign({}, values);
  if (isEmpty(formattedSearchInput)) return formattedSearchInput;
  if (formattedSearchInput.keywordsOrMapId) {
    formattedSearchInput.keywordsOrMapId = formattedSearchInput.keywordsOrMapId.trim();
  }
  if (formattedSearchInput.creator?.name === '') {
    formattedSearchInput.creator.name = null;
  }
  if (formattedSearchInput.fromYear) {
    formattedSearchInput.fromYear = parseInt(formattedSearchInput.fromYear);
  }
  if (formattedSearchInput.toYear) {
    formattedSearchInput.toYear = parseInt(formattedSearchInput.toYear);
  }
  if (formattedSearchInput.region && formattedSearchInput.region[0]?.id) {
    formattedSearchInput.region = parseInt(formattedSearchInput.region[0].id);
  } else {
    formattedSearchInput.region = null;
  }
  if (formattedSearchInput.language && formattedSearchInput.language[0]) {
    formattedSearchInput.language = formattedSearchInput.language[0].value;
  } else {
    formattedSearchInput.language = null;
  }

  return formattedSearchInput;
};

export const getRegions = gql`
  {
    regions {
      id
      fullName
    }
  }
`;
export const getLanguages = gql`
  {
    languages
  }
`;
