import React from "react";
import { capitalCase } from "capital-case";
import * as PropTypes from "prop-types";

export function LineItem(props) {
  return <div className="section-line-item" key={props.map?.id}>
    <div class="row">
      <div className="col-md-3 title">
        {capitalCase(props.input)}
      </div>
      <div className="col-md-9">
        {props.map && props.input.toLowerCase() === 'scale' ?
          `${props.map[props.input] ? `1:${props.map[props.input].toLocaleString()}` : ''}`
          :
          props.map ? props.map[props.input] : ''
        }
      </div>
    </div>
  </div>;
}

LineItem.propTypes = {
  input: PropTypes.any,
  map: PropTypes.any
};