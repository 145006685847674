import React, { useState } from 'react';
import './style.scss'

const SearchBarView = ({submitSearch}) => {
  const [searchInput, setSearchInput] = useState(null)

  return (
    <div className="search-bar-container">
        <div className="container">
            <div className="row">
                <div className="col search-input-container">
                    <input className="search-input" onChange={e => setSearchInput(e.target.value)} placeholder='Search Catalog Using Any or All Filters'/>
                    <div className="search-by-container">
                        <button className="text-button" onClick={() => submitSearch(searchInput)}>Search</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SearchBarView;