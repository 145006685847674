import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import './style.scss';
import Image from 'react-bootstrap/cjs/Image';
import facebookLogo from '../../assets/images/Social-Facebook-Black.svg';
import twitterLogo from '../../assets/images/Social-Twitter-Black.svg';
import linkedInLogo from '../../assets/images/icon-linkedin.svg';
import emailLogo from '../../assets/images/Social-Email.svg';
import moment from 'moment';
import { CreatorLineItem } from './components/CreatorLineItem';
import { LineItem } from './components/LineItem';
import useZoomifyScript from '../../services/UseZoomifyScript';
import { Link } from 'react-router-dom';
import { Api } from '../../services/Api';
import { useMainContext } from '../../services/Context/Context';

import {
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from 'react-share';
import { WP_ROOT_URL } from '../../constants/ApiConstants';
import { padWithZero } from '../../helpers/text';

const MapDetailView = (props) => {
  let dateFormat = 'MMM D, YYYY';

  const [authenticated, setAuthenticated] = useState(false);
  const [imageExists, setImageExists] = useState(true);

  const getMapUrl = useCallback(() => {
    if (props?.map?.number) {
      return `https://images.afriterra.org/low-res/${padWithZero(props?.map?.number, 5)}.jpg`;
    } else return '';
  }, [props?.map?.number]);

  const interactive = useMemo(
    () =>
      props?.map?.number && imageExists && !(props.map?.number === 74 || authenticated)
        ? 'cta-container--interactive'
        : '',
    [props?.map?.number, imageExists],
  );
  useEffect(() => {
    if (state.user?.token) {
      Api.postValidation(`${WP_ROOT_URL}/wp-json/jwt-auth/v1/token/validate`,
        null,
        state.user.token,
      ).then((res) => {
        if (res.code === 'jwt_auth_valid_token' && res.data?.status === 200) setAuthenticated(true);
        else setAuthenticated(false);
      });
    }
  });

  useZoomifyScript(
    'zoomify',
    props.map && props.map.number,
    props.map && props.map['imageUrl'],
    authenticated,
  );

  const { state } = useMainContext();

  return (
    <div className="container map-container">
      <div className="row">
        <div className='col-md-12'>
          <div className='map-share-container'>
            <div className='header'>
              <span>Map ID: {props.map && props.map.number}</span>
            </div>
            <div className='body'>
              {/* <btn className="btn-bookmark">Bookmark Map</btn> */}
              {/* <button className="btn-download">Download Map</button> */}
              <span className='share-title'> Share This Map</span>
              <div className='share-icons-container'>
                <FacebookShareButton url={window.location.href}>
                  <div className='share-icon'>
                    <Image src={facebookLogo} />
                  </div>
                </FacebookShareButton>
                <TwitterShareButton title={props.map?.title} url={window.location.href}>
                  <div className='share-icon'>
                    <Image src={twitterLogo} />
                  </div>
                </TwitterShareButton>
                <LinkedinShareButton
                  url={window.location.href}
                  title={props.map?.title}
                  summary='A map from Afriterra.org'
                  source='https://afriterra.org'
                >
                  <div className='share-icon'>
                    <Image src={linkedInLogo} />
                  </div>
                </LinkedinShareButton>
                <EmailShareButton
                  subject={'A map has been shared with you from Afriterra.org'}
                  body={`${window.location.href}`}
                  url={window.location.href}
                >
                  <div className='share-icon'>
                    <Image src={emailLogo} />
                  </div>
                </EmailShareButton>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <div className='map-image-container mt-4'>
            {!(props.map?.number === 74 || authenticated) && (
              <>
                <img
                  hidden={!imageExists}
                  className='map-image'
                  onLoad={() => setImageExists(true)}
                  onError={(e) => setImageExists(false)}
                  src={getMapUrl()}
                  alt='Map'
                />
                <div className={`cta-container ${interactive}`}>
                  <div className='cta-title'>Register or login to view interactive images.</div>
                  <a href={`${WP_ROOT_URL}/register`} className='cta-button'>
                    Sign Up
                  </a>
                  <div>
                    <span className='cta-text'>Already have an account?</span>{' '}
                    <Link to={`/login?mapID=${props?.map?.number}`} className='cta-link'>
                      {' '}
                      Sign In
                    </Link>
                  </div>
                </div>
              </>
            )}
            {(props.map?.number === 74 || authenticated) && props.map?.imageUrl !== null ? (
              <div id={`zoomifyContainer${props?.map?.number}`}></div>
            ) : null}
          </div>
        </div>
        <div className="col-12 row">
          {props.sections.map((section, idx) => (
            <div className="col-md-6" key={`${section}-${idx}`}>
              <div className='section-header'>{section.name}</div>
              {section.keys.map((key, idx) => {
                if (section.name === 'Creators') {
                  return <CreatorLineItem input={key} map={props.map} key={idx} />;
                } else {
                  return <LineItem input={key} map={props.map} key={idx} />;
                }
              })}
            </div>
          ))}
          <div className="col-12">
            <div className="section-header">Map Details & Notes</div>
            <p>{props.map && props.map['notes']}</p>
            <p>Last updated: {props.map && moment(props.map['timestamp']).format(dateFormat)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapDetailView;
