export const camelToTitle = (str) => {
  const sentence = str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, '$1');
  return `${sentence.charAt(0).toUpperCase()}${sentence.slice(1)}`;
};

export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const compareSearch = (element, searchTerm) =>
  !!element
    .replace(/[^a-zA-z0-9]/g, '')
    .toLowerCase()
    .includes(searchTerm.replace(/[^a-zA-z0-9]/g, '').toLowerCase());

export const titleCase = (str) => {
  if (str !== null) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      .join(' ');
  } else {
    return '';
  }
};

export const toPossessive = (str) => (str.match('i/s$/') ? `${str}'` : `${str}'s`);

export const padWithZero = (num, length) => {
  let result = '' + num;
  while (result.length < length) result = '0' + result;
  return result;
};
