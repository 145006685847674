import { capitalCase } from "capital-case";
import * as PropTypes from "prop-types";
import React from "react";
import '../style.scss';

export function CreatorLineItem(props) {
  let creators = props.map && props.map[props.input].map((creator) => creator.organization ?
    creator.organization : ` ${creator.firstName ? creator.firstName : ''}${creator.middleName ? ` ${creator.middleName} ` : ' '}${creator.lastName ? creator.lastName : ''}${creator.suffix ? ` ${creator.suffix}` : ''}`)
  return (<div className="section-line-item">
    <div className="row">
      <div className="col-md-3 title">
        {capitalCase(props.input)}
      </div>
      <div className="col-md-9 creators">
        {creators && creators.map(c => <div key={c}>{c}</div>)}
      </div>
    </div>
  </div>);
}

CreatorLineItem.propTypes = {
  input: PropTypes.any,
  map: PropTypes.any
};