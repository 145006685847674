import React from 'react';
import FooterView from "./view";


const Footer = () => {

    return (
        <FooterView />
    )
}

export default  Footer;